import React from "react"
import { getActiveLanguage } from "../utils/helper"

const defaultState = {
  showPopup: () => { },
  popupShow: false,
  showLogoPopup: () => { },
  logoPopupShow: null,
  setLanguage: () => { },
  lang: "default",
  setCurrentPath: () => { },
  curPath: "/",
  nav: {
    nav_show: null,
    setNavShow: () => { },
    open: false,
    setOpen: () => { },
    handleOpen: () => { },
    handleOpenwithArgs: () => { }
  }
}

const DataContext = React.createContext(defaultState)

class ContextProvider extends React.Component {

  state = {
    popupShow: false,
    logoPopupShow: null,
    lang: 'default',
    curPath: '/',
    nav: {
      nav_show: null,
      open: false,
    }
  }

  showPopup = (value) => {
    if (value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({ ...this.state, popupShow: value })

  }
  showLogoPopup = (value) => {
    if (value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({ ...this.state, logoPopupShow: value })

  }

  setLanguage = (value) => {
    this.setState({ ...this.state, lang: value })
  }

  setCurrentPath = (value) => {
    this.setState({ ...this.state, lang: getActiveLanguage({ pathname: value }), curPath: value })
  }

  setNavShow = (value) => {
    let nav = this.state.nav
    this.setState({ ...this.state, nav: { ...nav, nav_show: value } })
  }
  setOpen = (value) => {
    let nav = this.state.nav
    this.setState({ ...this.state, nav: { ...nav, open: value } })
  }
  
  handleOpen = () => {
    if (!this.state.nav.open) {
      document.body.classList.add('popup-open')
    } else {
      document.body.classList.remove('popup-open')
    }
    let nav = this.state.nav
    this.setState({ ...this.state, nav: { ...nav, open: !nav.open, nav_show: null } })
  }

  handleOpenwithArgs = (uuid) =>{
    if (!this.state.nav.open) {
      document.body.classList.add('popup-open')
    } else {
      document.body.classList.remove('popup-open')
    }
    let nav = this.state.nav
    this.setState({ ...this.state, nav: { ...nav, open: !nav.open, nav_show: uuid } })
  }
  render() {
    const { children } = this.props
    const { popupShow, logoPopupShow, lang, curPath, nav } = this.state
    let updated_nav = {
      nav_show: nav.nav_show,
      setNavShow: this.setNavShow,
      open: nav.open,
      setOpen: this.setOpen,
      handleOpen: this.handleOpen,
      handleOpenwithArgs: this.handleOpenwithArgs
    }
    return (
      <DataContext.Provider
        value={{
          popupShow,
          showPopup: this.showPopup,
          logoPopupShow,
          showLogoPopup: this.showLogoPopup,
          lang,
          setLanguage: this.setLanguage,
          curPath,
          setCurrentPath: this.setCurrentPath,
          nav: updated_nav

        }}
      >
        {children}
      </DataContext.Provider>
    )
  }
}

export default DataContext

export { ContextProvider }