import { Link } from "gatsby"
import React from "react"
import { MARK_LINK, NODE_CODEBLOCK, NODE_OL, NODE_QUOTE, NODE_UL, render } from "storyblok-rich-text-react-renderer"
import { useStoryblokState, StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import Image from "../components/Image"
import quotemark from "../images/quote.png"
import date from 'date-and-time'
import uk from 'date-and-time/locale/uk'
import en from 'date-and-time/locale/en'
import { sbClient } from "./storyblok"
/** checks for any parsing errors **/
export const isJsonParsable = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (err) {
    return false
  }
}
/** returns the location path according to language **/
export const getPathName = ({ code, pathname }) => {
  switch (code) {
    case 'en':
      if (pathname?.startsWith('/en') || pathname?.startsWith('en')) {
        return getLink(pathname)
      } else {
        const path_string = getLink('/en' + `${pathname?.startsWith('/') ? pathname : `/${pathname}`}`)
        return path_string
      }
    default:
      if (pathname?.startsWith('/en') || pathname?.startsWith('en')) {
        const path_string = "/" + pathname.split('/').slice(2)?.join('/')
        return getLink(path_string)
      } else {
        return getLink(pathname)
      }
  }

}

/** special function to find out subitems for navigation menu **/
export const getSubitems = (data) => {
  const keys = ['Sub_Services', "Sub_Solutions"]
  let result = {}
  keys.forEach(key => {
    result[key] = data[key]
  })
  return result
}

/** returns a valid link **/
export const getLink = (url) => {
  const url_pre = url?.startsWith("/") ? url : "/" + url
  const url_final = url_pre[url_pre.length - 1] == '/' ? url_pre : url_pre + '/'
  return url_final
}

/** returns true if any subnav_item is active **/
export const isSubItemActive = (sub_items, location) => {
  return sub_items?.find(data => {
    if (isJsonParsable(data?.node?.content)) {
      var found = true
      getLink(JSON.parse(data?.node?.content)?.Link?.cached_url)?.split('/')?.map(item => {
        if (!location?.pathname?.split('/')?.includes(item)) {
          found = false
        }
        return item
      })
    }
    return found
  }) ? true : false
}

/** returns true if any subnav_item has images for preview **/
export const hasImages = (sub_items) => {
  return sub_items?.filter(data => {
    if (isJsonParsable(data?.node?.content)) {
      return JSON.parse(data?.node?.content)?.Image?.filename?.trim()?.length > 0
    }
    return false
  })?.length > 0
}

/** replaces home with empty string from slug  **/
export function rewriteSlug(slug) {
  let newSlug = slug
  newSlug = newSlug?.split('/').includes('home') ? newSlug.replace('home', '') : newSlug
  return newSlug
}

/** Speacial functions to get content from structured content **/

export function getContent(data) {
  if (typeof data == 'string') {
    return <p>{data}</p>
  }
  // const html = sbClient.richTextResolver.render(data)
  return <>{
    render(data, {
      markResolvers: {
        [MARK_LINK]: (children, props) => {
          const { linktype, href, target } = props;
          if (linktype === 'email') {
            // Email links: add `mailto:` scheme and map to <a>
            return <a href={`mailto:${href}`} >{children}</a>;
          }
          if (href.match(/^(https?:)?\/\//)) {
            // External links: map to <a>
            return <a href={href} target={target}>{children}</a>;
          }
          // Internal links: map to <Link>
          return <Link to={href} ><a>{children}</a></Link>;
        },
      },
      nodeResolvers: {
        [NODE_QUOTE]: (children, props) => {
          return <div className='quote'>
            <img src={quotemark} alt="" />
            <p>{children}</p>
          </div>
        },
        [NODE_UL]: (children, props) => {
          return <ul className="default-ul">{children}</ul>
        },
        [NODE_OL]: (children, props) => {
          return <ol className="default-ol">{children}</ol>
        }
      },
      defaultBlokResolver: (name, props) => {
        const blok = {
          ...props,
          component: name
        }

        return <StoryblokComponent blok={blok} key={props._uid} />
      },

    })}</>
}
export function getText(data) {
  return data?.text ? <p>{data.text}</p> : null
}
/** returns current language **/
export function getActiveLanguage(location) {
  let { pathname } = location || {}
  let language = pathname?.startsWith('/') ? pathname?.slice(0)?.split("/")[1].replace('/', '') : pathname?.split("/")[1].replace('/', '')
  let activeLanguage = ['en'].includes(language) ? language : 'default'
  return activeLanguage
}

/** converts json string to object **/
export function parse(str) {
  return isJsonParsable(str) ? JSON.parse(str) : null
}
/** converts date string to locale string **/
export function getDate(time, locale = 'en') {
  let locales = {
    // 'en': en,
  }
  date.locale(locales[locale] ?? uk)
  return date.format(new Date(time), 'D MMMM YYYY')
}

/** returns html string from rich text */
export const getHTMLContent = ( document ) =>{

  return sbClient.richTextResolver.render(document);
}