import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from "react"

export function useImage({ url, size }) {
    const data = useStaticQuery(graphql`
    query MyQuery {
        default: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 1920

                )
                }
                url
            }
            }
        }
        very_small: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 170
                )
                }
                url
            }
            }
        }
        small: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 320
                )
                }
                url
            }
            }
        }
        normal: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 500
                )
                }
                url
            }
            }
        }
        big: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 900

                )
                }
                url
            }
            }
        }
        very_big: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 1280
                )
                }
                url
            }
            }
        }
        large: allFile(filter: {extension: {nin: ["svg" , "pdf"]}}) {
            edges {
            node {
                childImageSharp {
                gatsbyImageData(
                    webpOptions: {quality: 100}
                    formats: WEBP
                    placeholder: NONE,
                    width : 1600
                )
                }
                url
            }
            }
        }
    }`)
    const image = data[size ?? 'default']?.edges?.find(i => i?.node?.url === url)?.node?.childImageSharp?.gatsbyImageData || null
    return image
}