import React from 'react'
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query'
import { Toast } from 'primereact/toast'
import { useEffect, useRef } from 'react'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
        },
    },
})

export const QueryClientProvider = ({ children }) => {
    const toast = useRef(null)

    useEffect(() => {
        queryClient.setDefaultOptions({
            ...queryClient.getDefaultOptions(),
            mutations: {
                onError(error) {
                    toast.current?.show({
                        closable: true,
                        severity: 'error',
                        content: error instanceof Error ? error.message : String(error),
                    })
                },
            },
        })
    }, [])

    return (
        <ReactQueryClientProvider client={queryClient}>
            <Toast ref={toast} position="top-left"/>
            {children}
        </ReactQueryClientProvider>
    )
}
