import React from 'react'
const { GatsbyImage } = require("gatsby-plugin-image")
const { useImage } = require("../../utils/useImage")

const Image = ({ src,img_size, ...props }) => {
    const regex = /^(http|https)/
    const updated_src = regex.test(src) ? src : src?.startsWith('//') ? "https:" + (src || '') : "https://" + (src || '')
    const image = useImage({ url: updated_src , size : img_size })
    if(!src || src?.length==0){
        return
    }
    return image ? <GatsbyImage image={image} {...props} /> : <img src={updated_src} {...props} />
}
export default Image